import React from "react";
import { Box, Link, Typography } from "@mui/material";
import Popup from "../Popup";
import UploadFileScreen from "./UploadFile";
import CircularProgressScreen from "./CircularProgress";
import FilesForUpload from "./FilesForUpload";
import SuccessUploadScreen from "./SuccessUpload";
import UploadAccordion from "./UploadAccordion";
import Cookies from "js-cookie";
import {
  duplicateFileCheck,
  initiateMultipartUpload,
  uploadFileAPi,
  uploadFileAPiChunks,
} from "../../services/api/fileUpload";
import moment from "moment";
import {
  calculateUploadStats,
  checkIfFileNameValid,
} from "../../utils/utilsfuns";
import { useDispatch } from "react-redux";
import { setError } from "../../store/error.slice";
import UploadFileBatch2 from "./UploadFileBatch2";
interface settingPopupProps {
  open: boolean;
  handleClose: () => void;
  uploadingFile: boolean;
  setUploadingFile: (newState: boolean) => void;
}

type File = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
  status?: { type: "success" | "failure"; message: string };
  isValid?: boolean;
};

type File1 = {
  name: string;
  deleted?: boolean;
  type: string;
  size: number;
  lastModified: number;
  edited?: boolean;
  status?: { type: "success" | "failure"; message: string };
  isValid?: boolean;
};

type Batch = {
  files: File[];
  isPublished: boolean;
  filesAdd?: File[];
  addedToBatch?: boolean;
  uploadStatus: string[];
};

type Batch1 = {
  files: File1[];
  filesAdd?: File1[];
};
const UploadStatus = {
  INITIAL: "",
  WAITING: "Waiting...",
  Uploading: "uploading",
  COMPRESSING: "compressing",
  GENERATING: "genrating password",
  ENCRYPTING: "encrypting",
  COMPLETED: "completed",
  FAILED: "Failure",
};
const initialBatch: Batch = {
  files: [],
  isPublished: false,
  filesAdd: [],
  addedToBatch: false,
  uploadStatus: [UploadStatus.INITIAL],
};
const initialBatchName: Batch1 = {
  files: [],
  filesAdd: [],
};
const initialBatchState = {
  selectedStartDate: null as Date | null,
  fromDateFocused: false,
  time: "",
};
const FileUpload = ({
  open,
  handleClose,
  uploadingFile,
  setUploadingFile,
}: settingPopupProps) => {
  const verifyAccessString = Cookies.get("authAccess");
  const dispatch = useDispatch();
  const verifyAccess = verifyAccessString
    ? JSON.parse(verifyAccessString)
    : null;
  const [isPopupOpen, setIsPopupOpen] = React.useState(open);
  const [isDragging, setIsDragging] = React.useState(false);
  const [isDraggingBatch, setIsDraggingBatch] = React.useState(false);
  const [filesAdd, setFilesAdd] = React.useState<File[]>([]);
  const [filesAddBatch2, setFilesAddBatch2] = React.useState<File[]>([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showNewLoader, setShowNewLoader] = React.useState(false);
  const [showFileList, setShowFileList] = React.useState(false);
  const [invalidType, setInvalidType] = React.useState<string | null>(null);
  const [invalidTypeAdd, setInvalidTypeAdd] = React.useState(false);
  const [invalidBatchIndexes, setInvalidBatchIndexes] = React.useState<
    number[]
  >([]);
  const [uploadingBatchIndex, setUploadingBatchIndex] = React.useState<
    number | null
  >(null);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [addMoreFile, setAddMoreFile] = React.useState(false);
  const [addMoreFileBatch2, setAddMoreFileBatch2] = React.useState(false);
  const [editedAddFileNames, setEditedAddFileNames] = React.useState<
    Record<number, Record<number, { edited: string; original: string }>>
  >({});
  const [uploadBack, setUploadBack] = React.useState(false);
  const [editedFileNames, setEditedFileNames] = React.useState<
    Record<number, Record<number, { edited: string; original: string }>>
  >({});
  const [editingIndex, setEditingIndex] = React.useState<
    Record<number, number | null>
  >({});
  const [editingAddIndexes, setEditingAddIndexes] = React.useState<
    Record<number, number | null>
  >({});
  const [publishedDateTime, setPublishedDateTime] = React.useState<string[]>(
    []
  );
  const [timestamp, setTimestamp] = React.useState<number | null>(null);
  const [createNewBatch, setCreateNewBatch] = React.useState(false);

  const [batches, setBatches] = React.useState<Batch[]>([initialBatch]);
  const [batchesName, setBatchesName] = React.useState<Batch1[]>([
    initialBatchName,
  ]);
  const [currentBatchIndex, setCurrentBatchIndex] = React.useState(0);
  const currentBatch = batches[currentBatchIndex];
  const [cancelUpload, setCancelUpload] = React.useState<{
    isOpen: boolean;
    batchIndex: number | null;
  }>({ isOpen: false, batchIndex: null });
  const [successUpload, setSuccessUpload] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [replacedFiles, setReplacedFiles] = React.useState<
    Record<number, boolean>
  >({});
  const [fileProgress, setFileProgress] = React.useState<number[]>([]);
  const [fileProgressBatch2, setFileProgressBatch2] = React.useState<number[]>(
    []
  );
  const [batchProgress, setBatchProgress] = React.useState<number[]>([0]);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(
    Array(batches.length).fill(false)
  );
  const [uploadedStatus, setUploadedStatus] = React.useState(
    new Array(batches.length).fill(false)
  );
  const [uploadedStatusBatch2, setuploadedStatusBatch2] = React.useState(
    new Array(batches.length).fill(false)
  );
  const [batchLoaders, setBatchLoaders] = React.useState<boolean[]>(
    Array(batches.length).fill(false)
  );
  const [draggedBatchIndex, setDraggedBatchIndex] = React.useState<
    number | undefined
  >(undefined);
  const [uploadQueue, setUploadQueue] = React.useState<number[]>([]);
  const [uploadSpeed, setUploadSpeed] = React.useState({});
  const [uploadSpeedBatch2, setUploadSpeedBatch2] = React.useState({});
  const [toast, setToast] = React.useState<{
    type: string;
    message: string;
  } | null>(null);
  const [batchStates, setBatchStates] = React.useState<
    Array<typeof initialBatchState>
  >([initialBatchState]);

  const hanldeCreateNewBatch = () => {
    setBatches((prev: Batch[]) => {
      prev.push({
        files: [],
        isPublished: false,
        filesAdd: [],
        addedToBatch: false,
        uploadStatus: [UploadStatus.INITIAL],
      });
      return prev;
    });
    setBatchesName((prev: Batch1[]) => {
      prev.push({
        files: [],
        filesAdd: [],
      });
      return prev;
    });
    setBatchStates((prev: Array<typeof initialBatchState>) => {
      prev.push(initialBatchState);
      return prev;
    });
    setBatchProgress((prev: Array<number>) => {
      prev.push(0);
      return prev;
    });

    setCreateNewBatch(true);
  };
  const setUploadStatus = (
    status: string,
    uploadingBatchIndex: number,
    file_index: number
  ) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const data = [...newBatches[uploadingBatchIndex].uploadStatus];
      data[file_index] = status;
      if (uploadingBatchIndex !== null) {
        newBatches[uploadingBatchIndex] = {
          ...newBatches[uploadingBatchIndex],
          uploadStatus: data,
        };
      }
      return newBatches;
    });
  };
  const uploadFile=async(params:any)=>{
    const token = verifyAccess.token;
    const {batch_index,files,timestamp,uploadId,fileindex} =params
    const chunkSize = 1024 * 1024 * 5; // 10MB
    const totalChunks = Math.ceil(files.size / chunkSize);
    let start = 0;
    let remainingSize=files.size-start
    let end = remainingSize>chunkSize?(start+chunkSize):start+remainingSize
    let fileProgress=0
    const parts:any=[]
    for (let i = 1; i <= totalChunks; i++) {
        const chunk = files.slice(start, end);
        const formData = new FormData();
        formData.append("chunk", new Blob([chunk], { type: files.type }), files.name); 
        formData.append('originalname', files.name);
        formData.append('chunkNumber', `${i}`);
        formData.append('totalChunks', `${totalChunks}`);
        formData.append('uploadId', `${uploadId}`);
        formData.append('parts', `${JSON.stringify(parts)}`);
        await uploadFileAPiChunks(formData,token, (ProgressEvent)=>{
          const { total,bytes } = ProgressEvent;
          const {
            uploadSpeed,
            timeRemaining,
          } = calculateUploadStats(ProgressEvent,totalChunks);
          
          if(total){
            fileProgress=  fileProgress+bytes
            const percentCompleted=  Math.round((fileProgress/files.size)* 80)
            setUploadSpeed({
              percentCompleted: percentCompleted,
              uploadSpeed: uploadSpeed,
              timeRemaining: timeRemaining,
              bytes: fileProgress/(1024*1024),
              totalBytes: files.size/(1024*1024),
            });

            console.log("fileProgressjitendra",fileProgress,total,ProgressEvent)
            console.log("fileProgress1",totalChunks)
            setUploadStatus(
              UploadStatus.Uploading,
              batch_index,
              parseInt(fileindex)
            );
            setFileProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[parseInt(fileindex)]=percentCompleted;
              return newProgress;
            });
          }

        }) .then((res) => {
          if (res.status === 200) {
            start=end
            remainingSize=files.size-start
            end=remainingSize>chunkSize?(start+chunkSize):start+remainingSize
            if (res && res.data) {
              res.data?.data && parts.push(res.data?.data)
              if(i===totalChunks){
              setUploadStatus(
                UploadStatus.COMPRESSING,
                batch_index,
                parseInt(fileindex)
              );
              setFileProgress((prevProgress) => {
                const newProgress = [...prevProgress];
                newProgress[parseInt(fileindex)] = 85;
                return newProgress;
              });
              setTimeout(() => {
                setUploadStatus(
                  UploadStatus.ENCRYPTING,
                  batch_index,
                  parseInt(fileindex)
                );
                setFileProgress((prevProgress) => {
                  const newProgress = [...prevProgress];
                  newProgress[parseInt(fileindex)] = 90;
                  return newProgress;
                });
                setTimeout(() => {
                  setUploadStatus(
                    UploadStatus.COMPLETED,
                    batch_index,
                    parseInt(fileindex)
                  );
                  setFileProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[parseInt(fileindex)] = 100;
                    return newProgress;
                  });
                }, 1000);
              }, 1000);}
            }
          } else {
            const errorMessage = "Something went wrong";
            console.log("errorMessage", errorMessage);
            setUploadStatus(
              UploadStatus.FAILED,
              batch_index,
              parseInt(fileindex)
            );
            setFileProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[parseInt(fileindex)] = 100;
              return newProgress;
            });
            dispatch(
              setError({
                message: res?.data?.data?.message || errorMessage,
                type: "error",
                time: new Date().toLocaleString(),
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setFileProgress((prevProgress) => {
            const newProgress = [...prevProgress];
            newProgress[parseInt(fileindex)] = 100;
            return newProgress;
          });
          setUploadStatus(
            UploadStatus.FAILED,
            batch_index,
            parseInt(fileindex)
          );
        });
    }
    console.log(batch_index,files,timestamp)
  }
  const uploadFilebatch=async(params:any)=>{
    alert("dad")
    try {
      const token = verifyAccess.token;
      const {batch_index,files,timestamp,uploadId,fileindex} =params
      const chunkSize = 1024 * 1024 * 5; // 10MB
      const totalChunks = Math.ceil(files.size / chunkSize);
      let start = 0;
      let remainingSize=files.size-start
      let end = remainingSize>chunkSize?(start+chunkSize):start+remainingSize
      let fileProgress=0
      const parts:any=[]
      alert(totalChunks)
      for (let i = 1; i <= totalChunks; i++) {
          const chunk = files.slice(start, end);
          const formData = new FormData();
          formData.append("chunk", new Blob([chunk], { type: files.type }), files.name); 
          formData.append('originalname', files.name);
          formData.append('chunkNumber', `${i}`);
          formData.append('totalChunks', `${totalChunks}`);
          formData.append('uploadId', `${uploadId}`);
          formData.append('parts', `${JSON.stringify(parts)}`);
          await uploadFileAPiChunks(formData,token, (ProgressEvent)=>{
            const { total,bytes } = ProgressEvent;
            const {
              uploadSpeed,
              timeRemaining,
            } = calculateUploadStats(ProgressEvent,totalChunks);
            
            if(total){
              fileProgress=  fileProgress+bytes
              const percentCompleted=  Math.round((fileProgress/files.size)* 80)
              setUploadSpeedBatch2({
                      percentCompleted: percentCompleted,
                      uploadSpeed: uploadSpeed,
                      timeRemaining: timeRemaining,
                      bytes: fileProgress/(1024*1024),
                      totalBytes: files.size/(1024*1024),
                    });
              console.log("batch2fileProgressjitendra",fileProgress,total,ProgressEvent)
              console.log("batch2fileProgress1",totalChunks)
              setUploadStatus(
                UploadStatus.Uploading,
                batch_index,
                parseInt(fileindex)
              );
              setFileProgressBatch2((prevProgress) => {
                const newProgress = [...prevProgress];
                newProgress[parseInt(fileindex)] = percentCompleted;
                return newProgress;
              });
            }
  
          }) .then((res) => {
            if (res.status === 200) {
              start=end
              remainingSize=files.size-start
              end=remainingSize>chunkSize?(start+chunkSize):start+remainingSize
              if (res && res.data) {
                res.data?.data && parts.push(res.data?.data)
                if(i===totalChunks){
                setUploadStatus(
                  UploadStatus.COMPRESSING,
                  batch_index,
                  parseInt(fileindex)
                );
                setFileProgressBatch2((prevProgress) => {
                  const newProgress = [...prevProgress];
                  newProgress[parseInt(fileindex)] = 85;
                  return newProgress;
                });
                setTimeout(() => {
                  setUploadStatus(
                    UploadStatus.ENCRYPTING,
                    batch_index,
                    parseInt(fileindex)
                  );
                  setFileProgressBatch2((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[parseInt(fileindex)] = 90;
                    return newProgress;
                  });
                  setTimeout(() => {
                    setUploadStatus(
                      UploadStatus.COMPLETED,
                      batch_index,
                      parseInt(fileindex)
                    );
                    setFileProgressBatch2((prevProgress) => {
                      const newProgress = [...prevProgress];
                      newProgress[parseInt(fileindex)] = 100;
                      return newProgress;
                    });
                  }, 1000);
                }, 1000);}
              }
            } else {
              const errorMessage = "Something went wrong";
              console.log("errorMessage", errorMessage);
              setUploadStatus(
                UploadStatus.FAILED,
                batch_index,
                parseInt(fileindex)
              );
              setFileProgressBatch2((prevProgress) => {
                const newProgress = [...prevProgress];
                newProgress[parseInt(fileindex)] = 100;
                return newProgress;
              });
              dispatch(
                setError({
                  message: res?.data?.data?.message || errorMessage,
                  type: "error",
                  time: new Date().toLocaleString(),
                })
              );
            }
          })
          .catch((err) => {
            console.log(err);
            setFileProgressBatch2((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[parseInt(fileindex)] = 100;
              return newProgress;
            });
            setUploadStatus(
              UploadStatus.FAILED,
              batch_index,
              parseInt(fileindex)
            );
          });
      }
      
      console.log(batch_index,files,timestamp)
    } catch (error) {
      alert("errr"+JSON.stringify(error))
      console.log("errr batche2"+error)
    }
  
  }
  const handleFileUpload = async (batchIndex: number) => {
    const batch = batches[batchIndex];
    console.log("batches in submit", batches);
    const filesToUpload = batch.files.filter((file) => !file.deleted);
    setUploadedStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[batchIndex] = false;
      return newStatus;
    });
    setIsUploading(true);
    setUploadingBatchIndex(batchIndex);
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const data = Array(filesToUpload.length).fill(UploadStatus.INITIAL);
      newBatches[batchIndex] = {
        ...newBatches[batchIndex],
        files: filesToUpload,
        uploadStatus: data,
      };
      return newBatches;
    });
    try {
      const token = verifyAccess.token;

      setBatches((prevBatches) => {
        const newBatches = [...prevBatches];
        const data = Array(filesToUpload.length).fill(UploadStatus.WAITING);
        newBatches[batchIndex] = {
          ...newBatches[batchIndex],
          uploadStatus: data,
        };
        return newBatches;
      });
      setFileProgress(Array(filesToUpload.length).fill(0));
      for (const [fileindex, file] of Object.entries(filesToUpload)) {
        const formData = new FormData();
        const params:any={
          batch_index:batchIndex
        }
        formData.append("batch_index", batchIndex as unknown as string);
        if (batchStates[batchIndex].selectedStartDate !== null) {
          const dateObj = moment(batchStates[batchIndex].selectedStartDate);
          const timeString = batchStates[batchIndex].time;
          const timeObj = moment(timeString, "HH:mm A");
          dateObj.set({
            hour: timeObj.hour(),
            minute: timeObj.minute(),
          });

          const timestamp = dateObj.format("YYYY-MM-DD HH:MM:ss");
          formData.append("publish_timestamp", timestamp);
          params["timestamp"]=timestamp
        }
        formData.append("file_names", file as unknown as Blob);
        params["files"]=file

        console.log("batchStates", batchStates);
        setUploadingFile(true);
        await initiateMultipartUpload(file.name,token).then(async(res)=>{
          const uploadId=res.data.data
          params.uploadId=uploadId
          params.fileindex=fileindex
          await uploadFile(params)
        }).catch((error)=>{
          console.log(error);
        })
      }
    } catch (error: any) {
      const errorMessage = "Something went wrong";
      dispatch(
        setError({
          message: error?.response?.data?.message || errorMessage,
          type: "error",
          time: new Date().toLocaleString(),
        })
      );
      setToast({
        type: "error",
        message: errorMessage,
      });
      console.error("Error fetching student details:", error);
    }

    if (uploadingBatchIndex !== null || uploadQueue.length > 0) {
      setUploadQueue((prevQueue) => [...prevQueue, batchIndex]);
      return;
    }
  };
  const handleFileUploadBatch = async (batchIndex: number, batch: Batch) => {
    alert("batch upload")
    console.log("batches123", batches);
    const filesToUpload = batch.files.filter((file) => !file.deleted);
    setuploadedStatusBatch2((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[batchIndex] = false;
      return newStatus;
    });
    setIsUploading(true);
    setUploadingBatchIndex(batchIndex);
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const data = Array(filesToUpload.length).fill(UploadStatus.INITIAL);
      newBatches[batchIndex] = {
        ...newBatches[batchIndex],
        files: filesToUpload,
        uploadStatus: data,
      };
      return newBatches;
    });
    try {
      const token = verifyAccess.token;

      setBatches((prevBatches) => {
        const newBatches = [...prevBatches];
        const data = Array(filesToUpload.length).fill(UploadStatus.WAITING);
        newBatches[batchIndex] = {
          ...newBatches[batchIndex],
          uploadStatus: data,
        };
        return newBatches;
      });
      setFileProgressBatch2(Array(filesToUpload.length).fill(0));
      // let overall_progress = 0;
      for (const [fileindex, file] of Object.entries(filesToUpload)) {
        const formData = new FormData();
        formData.append("batch_index", batchIndex as unknown as string);
        const params:any={
          batch_index:batchIndex
        }
        if (batchStates[batchIndex].selectedStartDate !== null) {
          const dateObj = moment(batchStates[batchIndex].selectedStartDate);
          const timeString = batchStates[batchIndex].time;
          const timeObj = moment(timeString, "HH:mm A");
          dateObj.set({
            hour: timeObj.hour(),
            minute: timeObj.minute(),
          });
          
          const timestamp = dateObj.format("YYYY-MM-DD HH:MM:ss");
          formData.append("publish_timestamp", timestamp);
          params["timestamp"]=timestamp
        }
      
        
        formData.append("file_names", file as unknown as Blob);
        console.log("batchStates", batchStates);
        params["files"]=file

        setUploadingFile(true);
        await initiateMultipartUpload(file.name,token).then(async(res)=>{
          const uploadId=res.data.data
          params.uploadId=uploadId
          params.fileindex=fileindex
          await uploadFilebatch(params)
        }).catch((error)=>{
          console.log(error);
        })
        // await uploadFileAPi(formData, token, (ProgressEvent) => {
        //   console.log("onUploadProgress123", ProgressEvent);
        //   if (ProgressEvent.total) {
        //     console.log(
        //       "onUploadProgress",
        //       ProgressEvent.loaded / ProgressEvent.total
        //     );
        //     const {
        //       percentCompleted,
        //       uploadSpeed,
        //       timeRemaining,
        //       bytes,
        //       totalBytes,
        //     } = calculateUploadStats(ProgressEvent,5);
        //     console.log("percentCompleted", percentCompleted);

        //     overall_progress += percentCompleted;
        //     setUploadSpeedBatch2({
        //       percentCompleted: percentCompleted,
        //       uploadSpeed: uploadSpeed,
        //       timeRemaining: timeRemaining,
        //       bytes: bytes,
        //       totalBytes: totalBytes,
        //     });
        //     setUploadStatus(
        //       UploadStatus.Uploading,
        //       batchIndex,
        //       parseInt(fileindex)
        //     );
        //     setFileProgressBatch2((prevProgress) => {
        //       const newProgress = [...prevProgress];
        //       newProgress[parseInt(fileindex)] = percentCompleted;
        //       return newProgress;
        //     });
        //   } else {
        //     console.log("onUploadProgress123", ProgressEvent);
        //   }
        // })
        //   .then((res) => {
        //     if (res.status === 201) {
        //       if (res && res.data) {
        //         console.log("file uploaded", res);
        //         console.log("overall_progress", overall_progress);
        //         setUploadStatus(
        //           UploadStatus.COMPRESSING,
        //           batchIndex,
        //           parseInt(fileindex)
        //         );
        //         setFileProgressBatch2((prevProgress) => {
        //           const newProgress = [...prevProgress];
        //           newProgress[parseInt(fileindex)] = 85;
        //           return newProgress;
        //         });
        //         setTimeout(() => {
        //           setUploadStatus(
        //             UploadStatus.ENCRYPTING,
        //             batchIndex,
        //             parseInt(fileindex)
        //           );

        //           setFileProgressBatch2((prevProgress) => {
        //             const newProgress = [...prevProgress];
        //             newProgress[parseInt(fileindex)] = 90;
        //             return newProgress;
        //           });
        //           setTimeout(() => {
        //             setUploadStatus(
        //               UploadStatus.COMPLETED,
        //               batchIndex,
        //               parseInt(fileindex)
        //             );
        //             setFileProgressBatch2((prevProgress) => {
        //               const newProgress = [...prevProgress];
        //               newProgress[parseInt(fileindex)] = 100;
        //               return newProgress;
        //             });
        //           }, 1000);
        //         }, 1000);
        //       }
        //     } else {
        //       const errorMessage = "Something went wrong";
        //       console.log("errorMessage", errorMessage);
        //       setUploadStatus(
        //         UploadStatus.FAILED,
        //         batchIndex,
        //         parseInt(fileindex)
        //       );
        //       setFileProgressBatch2((prevProgress) => {
        //         const newProgress = [...prevProgress];
        //         newProgress[parseInt(fileindex)] = 100;
        //         return newProgress;
        //       });
        //       dispatch(
        //         setError({
        //           message: res?.data?.data?.message || errorMessage,
        //           type: "error",
        //           time: new Date().toLocaleString(),
        //         })
        //       );
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setFileProgressBatch2((prevProgress) => {
        //       const newProgress = [...prevProgress];
        //       newProgress[parseInt(fileindex)] = 100;
        //       return newProgress;
        //     });
        //     setUploadStatus(
        //       UploadStatus.FAILED,
        //       batchIndex,
        //       parseInt(fileindex)
        //     );
        //   });
      }
      // console.log("final", overall_progress);
    } catch (error: any) {
      const errorMessage = "Something went wrong";
      console.log("batches1234", error);
      dispatch(
        setError({
          message: error?.response?.data?.message || errorMessage,
          type: "error",
          time: new Date().toLocaleString(),
        })
      );
      setToast({
        type: "error",
        message: errorMessage,
      });
      console.error("Error fetching student details:", error);
    }

    if (uploadingBatchIndex !== null || uploadQueue.length > 0) {
      setUploadQueue((prevQueue) => [...prevQueue, batchIndex]);
      return;
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleAddMoreDragEnter = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    setDraggedBatchIndex(index);
  };

  const handleAddMoreDragLeave = () => {
    setDraggedBatchIndex(undefined);
  };

  const handleDragEnterBatch = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingBatch(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDragLeaveBatch = () => {
    setIsDraggingBatch(false);
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files) as File[];
    const invalidFiles = droppedFiles.filter((file) => !isValidFileType(file));
    const indevidualSizeInBytes = 1.5 * 1024 * 1024 * 1024; // 200 MB limit
    if (invalidFiles.length > 0) {
      setIsDragging(false);
      setInvalidType(
        "Invalid file type. Please upload only PDF and MP4 files."
      );
      return;
    }
    for (const files of droppedFiles) {
      if (files.size > indevidualSizeInBytes) {
        files.status = {
          type: "failure",
          message: "Max File Size: 1.5GB",
        };
      }
    }
    const replacedFilesCopy: Record<number, boolean> = {};
    setInvalidType(null);
    setIsDragging(false);
    setShowLoader(true);
    const startTime = Date.now();
    const datafiles = await uploadFiles(
      [...currentBatch.files, ...droppedFiles],
      startTime
    );
    setShowLoader(false);
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      droppedFiles.forEach((newFile) => {
        const existingFileIndex = newBatches[currentBatchIndex].files.findIndex(
          (file) => file.name === newFile.name
        );
        const existingFileServer = datafiles.findIndex(
          (file: any) => file.file_name === newFile.name && file.isExist
        );
        newFile.isValid =
          datafiles.filter((va: any) => va.file_name === newFile.name)[0]
            .isValidFileName || false;

        if (existingFileIndex !== -1) {
          newBatches[currentBatchIndex].files[existingFileIndex] = newFile;
          replacedFilesCopy[existingFileIndex] = true;
        } else if (existingFileServer !== -1) {
          newBatches[currentBatchIndex].files[existingFileServer] = newFile;
          replacedFilesCopy[existingFileServer] = true;
        } else {
          newBatches[currentBatchIndex].files.push(newFile);
          replacedFilesCopy[existingFileIndex] = false;
        }
      });
      return newBatches;
    });
    setBatchesName((prevBatches) => {
      const newBatches = [...prevBatches];
      droppedFiles.forEach((newFile, fileIndex) => {
        const existingFileIndex = newBatches[currentBatchIndex].files.findIndex(
          (file) => file.name === newFile.name
        );
        const existingFileServer = datafiles.findIndex(
          (file: any) => file.file_name === newFile.name && file.isExist
        );
        newFile.isValid =
          datafiles.filter((va: any) => va.file_name === newFile.name)[0]
            .isValidFileName || false;

        if (existingFileIndex !== -1) {
          newBatches[currentBatchIndex].files[existingFileIndex] = newFile;
          replacedFilesCopy[existingFileIndex] = true;
        } else if (existingFileServer !== -1) {
          newBatches[currentBatchIndex].files[existingFileServer] = newFile;
          replacedFilesCopy[existingFileServer] = true;
        } else {
          newBatches[currentBatchIndex].files.push(newFile);
          replacedFilesCopy[fileIndex] = false;
        }
      });
      return newBatches;
    });
    setShowFileList(true);
    setReplacedFiles((prevReplacedFiles) => ({
      ...prevReplacedFiles,
      ...replacedFilesCopy,
    }));
  };
  const handleDropNewBatch = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    await setCurrentBatchIndex((prevIndex) => prevIndex + 1);
    const droppedFiles = Array.from(e.dataTransfer.files) as File[];
    const invalidFiles = droppedFiles.filter((file) => !isValidFileType(file));
    const indevidualSizeInBytes = 1.5 * 1024 * 1024 * 1024; // 1.5 GB limit
    if (invalidFiles.length > 0) {
      setIsDraggingBatch(false);
      setInvalidType(
        "Invalid file type. Please upload only PDF and MP4 files."
      );
      return;
    }
    for (const files of droppedFiles) {
      if (files.size > indevidualSizeInBytes) {
        files.status = {
          type: "failure",
          message: "Max File Size: 1.5GB",
        };
      }
    }
    const replacedFilesCopy: Record<number, boolean> = {};
    setInvalidType(null);
    setIsDraggingBatch(false);
    setShowNewLoader(true);
    const startTime = Date.now();
    const datafiles = await uploadFiles(
      [...currentBatch.files, ...droppedFiles],
      startTime
    );
    setShowNewLoader(false);
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      droppedFiles.forEach((newFile) => {
        const existingFileIndex = newBatches[currentBatchIndex].files.findIndex(
          (file) => file.name === newFile.name
        );
        newFile.isValid =
          datafiles.filter((va: any) => va.file_name === newFile.name)[0]
            .isValidFileName || false;

        const existingFileServer = datafiles.findIndex(
          (file: any) => file.file_name === newFile.name && file.isExist
        );
        if (existingFileIndex !== -1) {
          newBatches[currentBatchIndex].files[existingFileIndex] = newFile;
          replacedFilesCopy[existingFileIndex] = true;
        } else if (existingFileServer !== -1) {
          newBatches[currentBatchIndex].files[existingFileServer] = newFile;
          replacedFilesCopy[existingFileServer] = true;
        } else {
          newBatches[currentBatchIndex].files.push(newFile);
          replacedFilesCopy[existingFileIndex] = false;
        }
      });
      return newBatches;
    });
    setBatchesName((prevBatches) => {
      const newBatches = [...prevBatches];
      droppedFiles.forEach((newFile, fileIndex) => {
        const existingFileIndex = newBatches[currentBatchIndex].files.findIndex(
          (file) => file.name === newFile.name
        );
        newFile.isValid =
          datafiles.filter((va: any) => va.file_name === newFile.name)[0]
            .isValidFileName || false;

        const existingFileServer = datafiles.findIndex(
          (file: any) => file.file_name === newFile.name && file.isExist
        );
        if (existingFileIndex !== -1) {
          newBatches[currentBatchIndex].files[existingFileIndex] = newFile;
          replacedFilesCopy[existingFileIndex] = true;
        } else if (existingFileServer !== -1) {
          newBatches[currentBatchIndex].files[existingFileServer] = newFile;
          replacedFilesCopy[existingFileServer] = true;
        } else {
          newBatches[currentBatchIndex].files.push(newFile);
          replacedFilesCopy[fileIndex] = false;
        }
      });
      return newBatches;
    });
    setShowFileList(true);
    setCreateNewBatch(false);
    setReplacedFiles((prevReplacedFiles) => ({
      ...prevReplacedFiles,
      ...replacedFilesCopy,
    }));
  };

  const handleDrop1 = async (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    const invalidFiles = droppedFiles.filter((file) => !isValidFileType(file));

    if (invalidFiles.length > 0) {
      setIsDragging(false);
      setInvalidTypeAdd(true);
      setInvalidBatchIndexes([index]);
      return;
    }
    setInvalidTypeAdd(false);
    setIsDragging(false);
    setBatchLoaders((prevLoaders) => {
      const newLoaders = [...prevLoaders];
      newLoaders[index] = true;
      return newLoaders;
    });
    const startTime = Date.now();
    await uploadFiles([...currentBatch.files, ...droppedFiles], startTime);
    setBatchLoaders((prevLoaders) => {
      const newLoaders = [...prevLoaders];
      newLoaders[index] = false;
      return newLoaders;
    });
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      if (index >= 0 && index < newBatches.length) {
        const currentBatch = newBatches[index];
        currentBatch.filesAdd = currentBatch.filesAdd || [];
        currentBatch.addedToBatch = true;
        currentBatch.filesAdd = droppedFiles;
      }
      return newBatches;
    });
    setBatchesName((prevBatches) => {
      const newBatches = [...prevBatches];
      if (index >= 0 && index < newBatches.length) {
        const currentBatch1 = newBatches[index];
        currentBatch1.filesAdd = currentBatch.filesAdd || [];
        currentBatch1.filesAdd = droppedFiles;
      }
      return newBatches;
    });
    setShowFileList(true);
  };

  const handleFileClick = (elemetID: string) => {
    const fileInput = document.getElementById(elemetID);
    if (fileInput) {
      fileInput.setAttribute("accept", ".pdf, .mp4");
      fileInput.setAttribute("multiple", "multiple");
      fileInput.click();
    }
  };
  const handleFileClickBatch2 = () => {
    const fileInput = document.getElementById("fileInput2");
    if (fileInput) {
      fileInput.setAttribute("accept", ".pdf, .mp4");
      fileInput.setAttribute("multiple", "multiple");
      fileInput.click();
    }
  };

  const isValidFileType = (file: File) => {
    const allowedFileTypes = ["application/pdf", "video/mp4"];
    return allowedFileTypes.includes(file.type);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const allowedFileTypes = ["application/pdf", "video/mp4"];
      const fileList = Array.from(selectedFiles) as File[];
      const isValidFile = fileList.every((file) =>
        allowedFileTypes.includes(file.type)
      );
      const invalidFiles = fileList.filter((file) => !isValidFileType(file));
      const indevidualSizeInBytes = 1.5 * 1024 * 1024 * 1024; // 200 MB limit
      // let totalSize = 0;
      if (invalidFiles.length > 0) {
        setIsDragging(false);
        setInvalidType(
          "Invalid file type. Please upload only PDF and MP4 files."
        );
        return;
      }
      for (const files of fileList) {
        if (files.size > indevidualSizeInBytes) {
          setInvalidType("Max File Size: 1.5GB");
          return;
        }
      }

      if (isValidFile) {
        setInvalidType(null);
        setShowLoader(true);
        const startTime = Date.now();
        const datafiles =
          (await uploadFiles(
            [...currentBatch.files, ...fileList],
            startTime
          )) || [];
        const replacedFilesCopy: Record<number, boolean> = {};
        setBatches((prevBatches) => {
          const newBatches = [...prevBatches];
          const currentBatch = newBatches[currentBatchIndex];
          fileList.forEach((newFile, fileIndex) => {
            const existingFileIndex = currentBatch.files.findIndex(
              (file) => file.name === newFile.name
            );
            const existingFileServer = datafiles.findIndex(
              (file: any) => file.file_name === newFile.name && file.isExist
            );
            newFile.isValid =
              datafiles.filter((va: any) => va.file_name === newFile.name)[0]
                .isValidFileName || false;
            if (existingFileIndex !== -1) {
              currentBatch.files[existingFileIndex] = newFile;
              replacedFilesCopy[existingFileIndex] = true;
            } else if (existingFileServer !== -1) {
              currentBatch.files[existingFileServer] = newFile;
              replacedFilesCopy[existingFileServer] = true;
            } else {
              currentBatch.files.push(newFile);
              replacedFilesCopy[fileIndex] = false;
            }
          });
          return newBatches;
        });
        setBatchesName((prevBatches) => {
          const newBatches = [...prevBatches];
          fileList.forEach((newFile, fileIndex) => {
            const existingFileIndex = newBatches[
              currentBatchIndex
            ].files.findIndex((file) => file.name === newFile.name);
            newFile.isValid =
              datafiles.filter((va: any) => va.file_name === newFile.name)[0]
                .isValidFileName || false;

            const existingFileServer = datafiles.findIndex(
              (file: any) => file.file_name === newFile.name && file.isExist
            );
            if (existingFileIndex !== -1) {
              newBatches[currentBatchIndex].files[existingFileIndex] = newFile;
              replacedFilesCopy[existingFileIndex] = true;
            } else if (existingFileServer !== -1) {
              newBatches[currentBatchIndex].files[existingFileServer] = newFile;
              replacedFilesCopy[existingFileServer] = true;
            } else {
              newBatches[currentBatchIndex].files.push(newFile);
              replacedFilesCopy[fileIndex] = false;
            }
          });
          return newBatches;
        });
        setShowLoader(false);
        setShowFileList(true);
        console.log("uploadFiles124", datafiles, replacedFilesCopy);
        setReplacedFiles((prevReplacedFiles) => ({
          ...prevReplacedFiles,
          ...replacedFilesCopy,
        }));
      }
    }
  };
  const handleFileChangeBatch2 = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = e.target.files;
    setCurrentBatchIndex((prevIndex) => prevIndex + 1);
    if (selectedFiles) {
      const allowedFileTypes = ["application/pdf", "video/mp4"];
      const fileList = Array.from(selectedFiles) as File[];
      const isValidFile = fileList.every((file) =>
        allowedFileTypes.includes(file.type)
      );
      const invalidFiles = fileList.filter((file) => !isValidFileType(file));
      const indevidualSizeInBytes = 1.5 * 1024 * 1024 * 1024; // 200 MB limit

      // let totalSize = 0;
      if (invalidFiles.length > 0) {
        setIsDragging(false);
        setInvalidType(
          "Invalid file type. Please upload only PDF and MP4 files."
        );
        return;
      }
      for (const files of fileList) {
        if (files.size > indevidualSizeInBytes) {
          setInvalidType("Max File Size: 1.5GB");
          return;
        }
      }

      if (isValidFile) {
        setInvalidType(null);
        setShowLoader(true);
        const startTime = Date.now();
        const datafiles = await uploadFiles(
          [...currentBatch.files, ...fileList],
          startTime
        );

        const replacedFilesCopy: Record<number, boolean> = {};
        setBatches((prevBatches) => {
          const newBatches = [...prevBatches];
          const currentBatch = newBatches[currentBatchIndex + 1];
          fileList.forEach((newFile) => {
            const existingFileServer = datafiles.findIndex(
              (file: any) => file.file_name === newFile.name && file.isExist
            );
            const existingFileIndex =
              currentBatch?.files?.findIndex(
                (file) => file?.name === newFile.name
              ) || -1;
            newFile.isValid =
              datafiles.filter((va: any) => va.file_name === newFile.name)[0]
                .isValidFileName || false;

            if (existingFileIndex !== -1) {
              currentBatch.files[existingFileIndex] = newFile;
              replacedFilesCopy[existingFileIndex] = true;
            } else if (existingFileServer !== -1) {
              currentBatch.files[existingFileServer] = newFile;
              replacedFilesCopy[existingFileServer] = true;
            } else {
              currentBatch.files.push(newFile);
              replacedFilesCopy[currentBatch.files.length - 1] = false;
            }
          });
          return newBatches;
        });
        setBatchesName((prevBatches) => {
          const newBatches = [...prevBatches];
          fileList.forEach((newFile, fileIndex) => {
            const existingFileIndex = newBatches[
              currentBatchIndex + 1
            ].files.findIndex((file) => file?.name === newFile.name);
            newFile.isValid =
              datafiles.filter((va: any) => va.file_name === newFile.name)[0]
                .isValidFileName || false;

            const existingFileServer = datafiles.findIndex(
              (file: any) => file.file_name === newFile.name && file.isExist
            );
            if (existingFileIndex !== -1) {
              newBatches[currentBatchIndex + 1].files[existingFileIndex] =
                newFile;
              replacedFilesCopy[existingFileIndex] = true;
            } else if (existingFileServer !== -1) {
              newBatches[currentBatchIndex + 1].files[existingFileServer] =
                newFile;
              replacedFilesCopy[existingFileServer] = true;
            } else {
              newBatches[currentBatchIndex + 1].files.push(newFile);
              replacedFilesCopy[fileIndex] = false;
            }
          });
          return newBatches;
        });

        setShowLoader(false);
        setShowFileList(true);
        setReplacedFiles({ ...replacedFiles, ...replacedFilesCopy });
      }
    }
  };
  const handleFileChange1 = async (
    e: React.ChangeEvent<HTMLInputElement>,
    currentBatchIndex: number
  ) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const allowedFileTypes = ["application/pdf", "video/mp4"];
      const fileList = Array.from(selectedFiles) as File[];
      const isValidFile = fileList.every((file) =>
        allowedFileTypes.includes(file.type)
      );
      const invalidFiles = fileList.filter((file) => !isValidFileType(file));
      const indevidualSizeInBytes = 1.5 * 1024 * 1024 * 1024; // 200 MB limit

      if (invalidFiles.length > 0) {
        setIsDragging(false);
        setInvalidType(
          "Invalid file type. Please upload only PDF and MP4 files."
        );
        return;
      }
      for (const files of fileList) {
        if (files.size > indevidualSizeInBytes) {
          setInvalidType("Max File Size: 1.5GB");
          return;
        }
      }
      if (isValidFile) {
        setInvalidType(null);
        setBatchLoaders((prevLoaders) => {
          const newLoaders = [...prevLoaders];
          newLoaders[currentBatchIndex] = true;
          return newLoaders;
        });
        const startTime = Date.now();
        await uploadFiles([...filesAdd, ...fileList], startTime);
        setBatchLoaders((prevLoaders) => {
          const newLoaders = [...prevLoaders];
          newLoaders[currentBatchIndex] = false;
          return newLoaders;
        });
        setBatches((prevBatches) => {
          const newBatches = [...prevBatches];
          if (currentBatchIndex >= 0 && currentBatchIndex < newBatches.length) {
            const currentBatch = newBatches[currentBatchIndex];
            currentBatch.filesAdd = currentBatch.filesAdd || [];
            currentBatch.addedToBatch = true;
            currentBatch.filesAdd = fileList;
          }
          return newBatches;
        });
        setBatchesName((prevBatches) => {
          const newBatches = [...prevBatches];
          if (currentBatchIndex >= 0 && currentBatchIndex < newBatches.length) {
            const currentBatch1 = newBatches[currentBatchIndex];
            currentBatch1.filesAdd = currentBatch.filesAdd || [];
            currentBatch1.filesAdd = fileList;
          }
          return newBatches;
        });
        setFilesAdd((prevFiles) => [...prevFiles, ...fileList]);
        setShowFileList(true);
      }
    }
  };
  const handleFileChangeAddtoBatch2 = async (
    e: React.ChangeEvent<HTMLInputElement>,
    currentBatchIndex: number
  ) => {
    const selectedFiles = e.target.files;
    if (selectedFiles) {
      const allowedFileTypes = ["application/pdf", "video/mp4"];
      const fileList = Array.from(selectedFiles) as File[];
      const isValidFile = fileList.every((file) =>
        allowedFileTypes.includes(file.type)
      );
      const invalidFiles = fileList.filter((file) => !isValidFileType(file));
      const indevidualSizeInBytes = 1.5 * 1024 * 1024 * 1024; // 200 MB limit

      // let totalSize = 0;
      if (invalidFiles.length > 0) {
        setIsDragging(false);
        setInvalidType(
          "Invalid file type. Please upload only PDF and MP4 files."
        );
        return;
      }
      for (const files of fileList) {
        // totalSize += files.size;
        if (files.size > indevidualSizeInBytes) {
          setInvalidType("Max File Size: 1.5GB");
          return;
        }
      }
      if (isValidFile) {
        setInvalidType(null);
        setBatchLoaders((prevLoaders) => {
          const newLoaders = [...prevLoaders];
          newLoaders[currentBatchIndex] = true;
          return newLoaders;
        });
        const startTime = Date.now();
        await uploadFiles([...filesAddBatch2, ...fileList], startTime);
        setBatchLoaders((prevLoaders) => {
          const newLoaders = [...prevLoaders];
          newLoaders[currentBatchIndex] = false;
          return newLoaders;
        });
        setBatches((prevBatches) => {
          const newBatches = [...prevBatches];
          if (currentBatchIndex >= 0 && currentBatchIndex < newBatches.length) {
            const currentBatch = newBatches[currentBatchIndex];
            currentBatch.filesAdd = currentBatch.filesAdd || [];
            currentBatch.addedToBatch = true;
            currentBatch.filesAdd = fileList;
          }
          return newBatches;
        });
        setBatchesName((prevBatches) => {
          const newBatches = [...prevBatches];
          if (currentBatchIndex >= 0 && currentBatchIndex < newBatches.length) {
            const currentBatch1 = newBatches[currentBatchIndex];
            currentBatch1.filesAdd = currentBatch.filesAdd || [];
            currentBatch1.filesAdd = fileList;
          }
          return newBatches;
        });
        setFilesAddBatch2((prevFiles) => [...prevFiles, ...fileList]);
        setShowFileList(true);
      }
    }
  };

  const uploadFiles = async (uploadedFiles: File[], startTime: number) => {
    console.log("Uploading files:", uploadedFiles, startTime);
    const token = verifyAccess.token;
    const files: any = [];
    let returnData: any = [];
    await uploadedFiles.map((file, fileIndex) => {
      files.push({
        file_name: file.name,
        file_index: fileIndex,
      });
    });
    await duplicateFileCheck({ files: files }, token, (ProgressEvent) => {
      console.log("onUploadProgress123", ProgressEvent);
      if (ProgressEvent.total) {
        console.log(
          "onUploadProgress",
          ProgressEvent.loaded / ProgressEvent.total
        );
        setUploadProgress((ProgressEvent.loaded / ProgressEvent.total) * 80);
      } else {
        console.log("onUploadProgress123", ProgressEvent);
      }
    }).then(async (res) => {
      if (res.status) {
        const data = res.data?.data || [];
        returnData = data;
      }
    });
    return returnData;
  };

  const handleEditFileName = (batchIndex: number, fileIndex: number) => {
    setEditingIndex((prevIndexes) => ({
      ...prevIndexes,
      [batchIndex]: fileIndex,
    }));
  };

  const handleEditFileName1 = (batchIndex: number, fileIndex: number) => {
    setEditingAddIndexes((prevIndexes) => ({
      ...prevIndexes,
      [batchIndex]: fileIndex,
    }));
  };

  const handleDeleteFile = (batchIndex: number, fileIndex: number) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const batch = newBatches[batchIndex];

      if (batch && batch.files[fileIndex]) {
        const newFiles = [...batch.files];
        newFiles[fileIndex].deleted = true;

        newBatches[batchIndex] = {
          ...batch,
          files: newFiles,
        };
      }

      return newBatches;
    });
  };

  const handleDeleteFile1 = (batchIndex: number, fileIndex: number) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      if (batchIndex >= 0 && batchIndex < newBatches.length) {
        const currentBatch = newBatches[batchIndex];
        if (currentBatch) {
          const newFilesAdd = [...(currentBatch.filesAdd || [])];
          if (fileIndex >= 0 && fileIndex < newFilesAdd.length) {
            const fileToDelete = newFilesAdd[fileIndex];
            const deletedFile = {
              name: fileToDelete.name || "Unknown Name",
              type: fileToDelete.type || "Unknown Type",
              size: fileToDelete.size,
              lastModified: fileToDelete.lastModified,
              deleted: true,
            };
            newFilesAdd[fileIndex] = deletedFile;
            currentBatch.filesAdd = newFilesAdd;
          }
        }
      }
      return newBatches;
    });
  };

  const handleSaveFileName = (batchIndex: number, fileIndex: number) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const batch = newBatches[batchIndex];
      if (batch && batch.files && batch?.files[fileIndex]) {
        const editedName =
          editedFileNames[batchIndex]?.[fileIndex]?.edited ||
          batch.files[fileIndex].name;
        const originalName = batch.files[fileIndex].name;

        const originalBlob = batch.files[fileIndex] as unknown as Blob;

        const updatedFile = new File([originalBlob], editedName, {
          type: batch.files[fileIndex].type,
          lastModified: batch.files[fileIndex].lastModified,
        });
        batch.files[fileIndex] = updatedFile;
        batch.files[fileIndex].isValid = checkIfFileNameValid(updatedFile.name);
        newBatches[batchIndex] = batch;
        console.log("updatedFile", newBatches);
        setEditedFileNames((prev) => ({
          ...prev,
          [batchIndex]: {
            ...(prev[batchIndex] || {}),
            [fileIndex]: { edited: editedName, original: originalName },
          },
        }));

        setEditingIndex((prevIndexes) => ({
          ...prevIndexes,
          [batchIndex]: null,
        }));
      }
      return newBatches;
    });
  };

  const handleSaveFileName1 = (batchIndex: number, fileIndex: number) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const batch = newBatches[batchIndex];
      if (batch && batch.filesAdd && batch?.filesAdd[fileIndex]) {
        const editedName =
          editedAddFileNames[batchIndex]?.[fileIndex]?.edited ||
          batch?.filesAdd[fileIndex].name;
        const originalName = batch.filesAdd[fileIndex].name;

        const originalBlob = batch.filesAdd[fileIndex] as unknown as Blob;
        const updatedFile = new File([originalBlob], editedName, {
          type: batch.filesAdd[fileIndex].type,
          lastModified: batch.filesAdd[fileIndex].lastModified,
        });
        batch.filesAdd[fileIndex] = updatedFile;

        setEditedAddFileNames((prev) => ({
          ...prev,
          [batchIndex]: {
            ...(prev[batchIndex] || {}),
            [fileIndex]: { edited: editedName, original: originalName },
          },
        }));

        setEditingAddIndexes((prevIndexes) => ({
          ...prevIndexes,
          [batchIndex]: null,
        }));
      }
      return newBatches;
    });
  };

  const handleUndoDelete = (batchIndex: number, fileIndex: number) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const batch = newBatches[batchIndex];

      if (batch && batch.files[fileIndex]) {
        const newFiles = [...batch.files];
        newFiles[fileIndex].deleted = false;

        newBatches[batchIndex] = {
          ...batch,
          files: newFiles,
        };
      }

      return newBatches;
    });
  };

  const handleUndoDelete1 = (batchIndex: number, fileIndex: number) => {
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const updatedBatch = { ...newBatches[batchIndex] };
      if (!updatedBatch.filesAdd) {
        updatedBatch.filesAdd = [];
      }
      const updatedFilesAdd = [...updatedBatch.filesAdd];
      updatedFilesAdd[fileIndex] = {
        ...updatedFilesAdd[fileIndex],
        deleted: false,
      };
      updatedBatch.filesAdd = updatedFilesAdd;
      newBatches[batchIndex] = updatedBatch;
      return newBatches;
    });
  };

  const handleCancelEditFileName1 = (batchIndex: number) => {
    setDraggedBatchIndex(undefined);
    setEditingAddIndexes((prevIndexes) => ({
      ...prevIndexes,
      [batchIndex]: null,
    }));
    setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      if (batchIndex >= 0 && batchIndex < newBatches.length) {
        const currentBatch = newBatches[batchIndex] as Batch & {
          editedAddFileNames?: Record<number, string>;
          filesAdd?: File[];
        };
        currentBatch.filesAdd = [];
      }
      return newBatches;
    });
  };

  const handleAddToBatch = async (batchIndex: number) => {
    setDraggedBatchIndex(undefined);
    let filesToUpload: File[] = [];

    await setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      if (batchIndex >= 0 && batchIndex < newBatches.length) {
        const currentBatch = newBatches[batchIndex] as Batch & {
          files: File[];
          filesAdd: File[];
        };
        currentBatch.files = currentBatch.files || [];
        currentBatch.filesAdd = currentBatch.filesAdd || [];

        const replacedFilesCopy: Record<number, boolean> = {};

        const filesToAdd = currentBatch.filesAdd.filter(
          (newFile) => !newFile.deleted
        );
        filesToUpload = filesToAdd;

        currentBatch.files = currentBatch.files.map((file, index) => {
          const existingFileIndex = currentBatch.filesAdd.findIndex(
            (newFile) => newFile.name === file.name
          );
          if (existingFileIndex !== -1) {
            replacedFilesCopy[index] = true;
            return currentBatch.filesAdd[existingFileIndex];
          }
          return file;
        });

        currentBatch.files = [
          ...currentBatch.files,
          ...filesToAdd.filter(
            (newFile) =>
              !replacedFilesCopy[
                currentBatch.files.findIndex(
                  (file) => file.name === newFile.name
                )
              ]
          ),
        ];
        currentBatch.filesAdd = [];
        setEditingAddIndexes((prevIndexes) => ({
          ...prevIndexes,
          [batchIndex]: null,
        }));
      }
      return newBatches;
    });
    await handleCancelEditFileName1(batchIndex);
    console.log("updated values", batches);
    // upload logic
    await setUploadingBatchIndex(batchIndex);
    await setBatches((prevBatches) => {
      const newBatches = [...prevBatches];
      const data = Array(filesToUpload.length).fill(UploadStatus.INITIAL);
      newBatches[batchIndex] = {
        ...newBatches[batchIndex],
        uploadStatus: [...newBatches[batchIndex].uploadStatus, ...data],
      };
      return newBatches;
    });
    try {
      const token = verifyAccess.token;

      setBatches((prevBatches) => {
        const newBatches = [...prevBatches];
        const data = Array(filesToUpload.length).fill(UploadStatus.WAITING);
        newBatches[batchIndex] = {
          ...newBatches[batchIndex],
          uploadStatus: [...newBatches[batchIndex].uploadStatus, ...data],
        };
        return newBatches;
      });
      batchIndex === 1
        ? setFileProgressBatch2((prev) => [
            ...prev,
            ...Array(filesToUpload.length).fill(0),
          ])
        : setFileProgress((prev) => [
            ...prev,
            ...Array(filesToUpload.length).fill(0),
          ]);      let overall_progress = 0;
      for (const file of filesToUpload) {
        const fileindex = batches[batchIndex].files.findIndex(
          (va) => va.name === file.name
        );
        if (fileindex >= 0) {
          const formData = new FormData();
          formData.append("batch_index", batchIndex as unknown as string);
          if (batchStates[batchIndex].selectedStartDate !== null) {
            const dateObj = moment(batchStates[batchIndex].selectedStartDate);
            const timeString = batchStates[batchIndex].time;
            const timeObj = moment(timeString, "HH:mm A");
            dateObj.set({
              hour: timeObj.hour(),
              minute: timeObj.minute(),
            });

            const timestamp = dateObj.format("YYYY-MM-DD HH:MM:ss");
            formData.append("publish_timestamp", timestamp);
          }
          formData.append("file_names", file as unknown as Blob);
          console.log("batchStates", batchStates);

          setUploadingFile(true);
          await uploadFileAPi(formData, token, (ProgressEvent) => {
            console.log("onUploadProgress123", ProgressEvent);
            if (ProgressEvent.total) {
              console.log(
                "onUploadProgress",
                ProgressEvent.loaded / ProgressEvent.total
              );
              const {
                percentCompleted,
                uploadSpeed,
                timeRemaining,
                bytes,
                totalBytes,
              } = calculateUploadStats(ProgressEvent,5);
              console.log("percentCompleted", percentCompleted);

              overall_progress += percentCompleted;
              batchIndex === 1
                ? setUploadSpeedBatch2({
                    percentCompleted: percentCompleted,
                    uploadSpeed: uploadSpeed,
                    timeRemaining: timeRemaining,
                    bytes: bytes,
                    totalBytes: totalBytes,
                  })
                : setUploadSpeed({
                    percentCompleted: percentCompleted,
                    uploadSpeed: uploadSpeed,
                    timeRemaining: timeRemaining,
                    bytes: bytes,
                    totalBytes: totalBytes,
                  });
              setUploadStatus(UploadStatus.Uploading, batchIndex, fileindex);
              batchIndex === 1
                ? setFileProgressBatch2((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[fileindex] = percentCompleted;
                    return newProgress;
                  })
                : setFileProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[fileindex] = percentCompleted;
                    return newProgress;
                  });
            } else {
              console.log("onUploadProgress123", ProgressEvent);
            }
          })
            .then((res) => {
              if (res.status === 201) {
                if (res && res.data) {
                  console.log("file uploaded", res);
                  console.log("overall_progress", overall_progress);
                  setUploadStatus(
                    UploadStatus.COMPRESSING,
                    batchIndex,
                    fileindex
                  );
                  batchIndex === 1
                    ? setFileProgressBatch2((prevProgress) => {
                        const newProgress = [...prevProgress];
                        newProgress[fileindex] = 85;
                        return newProgress;
                      })
                    : setFileProgress((prevProgress) => {
                        const newProgress = [...prevProgress];
                        newProgress[fileindex] = 85;
                        return newProgress;
                      });
                  setTimeout(() => {
                    setUploadStatus(
                      UploadStatus.ENCRYPTING,
                      batchIndex,
                      fileindex
                    );
                    batchIndex === 1
                      ? setFileProgressBatch2((prevProgress) => {
                          const newProgress = [...prevProgress];
                          newProgress[fileindex] = 90;
                          return newProgress;
                        })
                      : setFileProgress((prevProgress) => {
                          const newProgress = [...prevProgress];
                          newProgress[fileindex] = 90;
                          return newProgress;
                        });
                    setTimeout(() => {
                      setUploadStatus(
                        UploadStatus.COMPLETED,
                        batchIndex,
                        fileindex
                      );
                      batchIndex === 1
                        ? setFileProgressBatch2((prevProgress) => {
                            const newProgress = [...prevProgress];
                            newProgress[fileindex] = 100;
                            return newProgress;
                          })
                        : setFileProgress((prevProgress) => {
                            const newProgress = [...prevProgress];
                            newProgress[fileindex] = 100;
                            return newProgress;
                          });
                    }, 1000);
                  }, 1000);
                }
              } else {
                const errorMessage = "Something went wrong";
                console.log("errorMessage", errorMessage);
                setUploadStatus(UploadStatus.FAILED, batchIndex, fileindex);
                batchIndex === 1
                  ? setFileProgressBatch2((prevProgress) => {
                      const newProgress = [...prevProgress];
                      newProgress[fileindex] = 100;
                      return newProgress;
                    })
                  : setFileProgress((prevProgress) => {
                      const newProgress = [...prevProgress];
                      newProgress[fileindex] = 100;
                      return newProgress;
                    });
                dispatch(
                  setError({
                    message: res?.data?.data?.message || errorMessage,
                    type: "error",
                    time: new Date().toLocaleString(),
                  })
                );
              }
            })
            .catch((err) => {
              console.log(err);
              batchIndex
                ? setFileProgressBatch2((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[fileindex] = 100;
                    return newProgress;
                  })
                : setFileProgress((prevProgress) => {
                    const newProgress = [...prevProgress];
                    newProgress[fileindex] = 100;
                    return newProgress;
                  });
              setUploadStatus(UploadStatus.FAILED, batchIndex, fileindex);
            });
        }
      }
      console.log("final", overall_progress);
    } catch (error: any) {
      const errorMessage = "Something went wrong";
      dispatch(
        setError({
          message: error?.response?.data?.message || errorMessage,
          type: "error",
          time: new Date().toLocaleString(),
        })
      );
      setToast({
        type: "error",
        message: errorMessage,
      });
      console.error("Error fetching student details:", error);
    }

    if (uploadingBatchIndex !== null || uploadQueue.length > 0) {
      setUploadQueue((prevQueue) => [...prevQueue, batchIndex]);
      return;
    }
  };

  const handleCancel = () => {
    const newBatches = batches.filter(
      (batch, index) => index !== currentBatchIndex
    );
    setBatches(newBatches);
    setCurrentBatchIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowFileList(false);
  };

  const successUploadMore = () => {
    setShowFileList(false);
    setUploadingFile(false);
    setSuccessUpload(false);
    setShowLoader(false);
    setAddMoreFile(false);
    setAddMoreFileBatch2(false);
  };

  const handlePopupClose = () => {
    window.location.reload();
    setUploadBack(false);
    setEditedFileNames({});
    setEditingIndex({});
    setEditingAddIndexes({});
    setPublishedDateTime([]);
    setBatches([initialBatch]);
    setBatchesName([initialBatchName]);
    setCurrentBatchIndex(0);
    setEditedAddFileNames({});
    setAddMoreFile(false);
    setAddMoreFileBatch2(false);
    setUploadingBatchIndex(null);
    setInvalidBatchIndexes([]);
    setFilesAdd([]);
    setFilesAddBatch2([]);
    handleClose();
    successUploadMore();
  };

  React.useEffect(() => {
    if (batches.length > 0) {
      const overall_progress: number = fileProgress.reduce(
        (acc: number, currentValue: number) => acc + currentValue,
        0
      );
      console.log(timestamp);

      console.log("fileProgress", fileProgress, batches, overall_progress);
      setBatchProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        newProgress[0] = overall_progress / fileProgress.length;
        return newProgress;
      });
      if (overall_progress === fileProgress.length * 100) {
        setUploadedStatus((prevStatus) => {
          const newStatus = [...prevStatus];
          newStatus[0] = true;
          return newStatus;
        });
        setIsUploading(false);
      }
    }
  }, [fileProgress, batches]);
  React.useEffect(() => {
    if (batches.length > 1) {
      const overall_progress: number = fileProgressBatch2.reduce(
        (acc: number, currentValue: number) => acc + currentValue,
        0
      );
      console.log(timestamp);

      console.log(
        "fileProgress",
        fileProgressBatch2,
        batches,
        overall_progress,
        currentBatchIndex
      );
      setBatchProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        newProgress[1] = overall_progress / fileProgressBatch2.length;
        return newProgress;
      });
      if (overall_progress === fileProgressBatch2.length * 100) {
        setUploadedStatus((prevStatus) => {
          const newStatus = [...prevStatus];
          newStatus[1] = true;
          return newStatus;
        });
        setIsUploading(false);
      }
    }
  }, [fileProgressBatch2, batches]);
  React.useEffect(() => {
    setIsPopupOpen(open);
  }, [open]);
  React.useEffect(() => {
    if (!uploadingFile && showFileList && currentBatch.files.length > 0) {
      setUploadBack(true);
    }
  });
  return (
    <Popup
      open={isPopupOpen}
      handleClose={handlePopupClose}
      uploadingFile={uploadingFile}
      title="Upload Files"
      handleButton={handleClose}
      width="650px"
      upload={uploadBack}
    >
      <>
        {!addMoreFile && !createNewBatch && (
          <>
            {!showLoader ? (
              <>
                {currentBatch.files.length === 0 && !uploadingFile && (
                  <UploadFileScreen
                    invalidType={invalidType}
                    isDragging={isDragging}
                    showLoader={showLoader}
                    handleDragEnter={handleDragEnter}
                    handleDragLeave={handleDragLeave}
                    handleDrop={handleDrop}
                    handleFileClick={(elementID: string) =>
                      handleFileClick(elementID)
                    }
                    handleFileChange={handleFileChange}
                  />
                )}
              </>
            ) : (
              <CircularProgressScreen
                showLoader={showLoader}
                uploadProgress={uploadProgress}
              />
            )}
          </>
        )}

        {!uploadingFile && showFileList && currentBatch.files.length > 0 && (
          <>
            {[batches[0]].map((batch, index) => (
              <Box key={index}>
                <FilesForUpload
                  uploadSpeed={uploadSpeed}
                  showLoader={showLoader}
                  index={index}
                  setTimestamp={setTimestamp}
                  isCalendarOpen={isCalendarOpen}
                  batchData={batch.files}
                  editingIndex={editingIndex}
                  editedFileNames={editedFileNames}
                  setEditedFileNames={setEditedFileNames}
                  handleSaveFileName={handleSaveFileName}
                  replacedFiles={replacedFiles}
                  handleEditFileName={handleEditFileName}
                  handleDeleteFile={handleDeleteFile}
                  handleUndoDelete={handleUndoDelete}
                  setAddMoreFile={setAddMoreFile}
                  handleCancel={handleCancel}
                  setIsCalendarOpen={setIsCalendarOpen}
                  setUploadingFile={setUploadingFile}
                  setBatches={setBatches}
                  batchStates={batchStates}
                  setBatchStates={setBatchStates}
                  handleFileUpload={handleFileUpload}
                  setPublishedDateTime={setPublishedDateTime}
                  invalidType={invalidType}
                  isDragging={isDragging}
                  handleDragEnter={handleDragEnter}
                  handleDragLeave={handleDragLeave}
                  handleDrop={handleDrop}
                  handleFileClick={(elementID: string) =>
                    handleFileClick(elementID)
                  }
                  handleFileChange={handleFileChange}
                  currentBatchIndex={currentBatchIndex}
                  currentBatch={currentBatch}
                  uploadProgress={uploadProgress}
                  addMoreFile={addMoreFile}
                  fileUpload={true}
                  batchesName={batchesName}
                  toast={toast}
                  setToast={setToast}
                />
              </Box>
            ))}
          </>
        )}

        {uploadingFile && (
          <>
            {[batches[0]].map((batch, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    background: "#fff",
                    padding: "8px 12px 24px 12px",
                    borderRadius: "16px",
                    marginBottom: "18px",
                  }}
                >
                  {batch.isPublished ? (
                    <UploadAccordion
                      index={0}
                      uploadSpeed={uploadSpeed}
                      uploadProgress={uploadProgress}
                      uploadedStatus={uploadedStatus}
                      batchData={batch}
                      publishedDateTime={publishedDateTime}
                      uploadingBatchIndex={0}
                      isUploading={isUploading}
                      batchProgress={batchProgress}
                      cancelUpload={cancelUpload}
                      batches={batches}
                      UploadStatus={UploadStatus}
                      setCancelUpload={setCancelUpload}
                      setBatches={setBatches}
                      currentBatchIndex={currentBatchIndex}
                      setCurrentBatchIndex={setCurrentBatchIndex}
                      setUploadingFile={setUploadingFile}
                      setShowFileList={setShowFileList}
                      editedFileNames={editedFileNames}
                      replacedFiles={replacedFiles}
                      fileProgress={fileProgress}
                      editingAddIndexes={editingAddIndexes}
                      editedAddFileNames={editedAddFileNames}
                      setEditedAddFileNames={setEditedAddFileNames}
                      handleSaveFileName1={handleSaveFileName1}
                      handleEditFileName1={handleEditFileName1}
                      handleDeleteFile1={handleDeleteFile1}
                      handleUndoDelete1={handleUndoDelete1}
                      handleCancelEditFileName1={handleCancelEditFileName1}
                      handleAddToBatch={handleAddToBatch}
                      batchLoaders={batchLoaders}
                      draggedBatchIndex={draggedBatchIndex}
                      handleAddMoreDragEnter={handleAddMoreDragEnter}
                      handleAddMoreDragLeave={handleAddMoreDragLeave}
                      handleDrop1={handleDrop1}
                      handleFileClick={(elementID: string) =>
                        handleFileClick(elementID)
                      }
                      handleFileChange1={(e) => handleFileChange1(e, 0)}
                      invalidBatchIndexes={invalidBatchIndexes}
                      invalidTypeAdd={invalidTypeAdd}
                    />
                  ) : (
                    <FilesForUpload
                      showLoader={showLoader}
                      index={index}
                      uploadSpeed={uploadSpeed}
                      isCalendarOpen={isCalendarOpen}
                      batchData={batch.files}
                      editingIndex={editingIndex}
                      editedFileNames={editedFileNames}
                      setEditedFileNames={setEditedFileNames}
                      handleSaveFileName={handleSaveFileName}
                      replacedFiles={replacedFiles}
                      handleEditFileName={handleEditFileName}
                      handleDeleteFile={handleDeleteFile}
                      handleUndoDelete={handleUndoDelete}
                      setAddMoreFile={setAddMoreFile}
                      handleCancel={handleCancel}
                      setIsCalendarOpen={setIsCalendarOpen}
                      setUploadingFile={setUploadingFile}
                      setBatches={setBatches}
                      batchStates={batchStates}
                      setBatchStates={setBatchStates}
                      handleFileUpload={handleFileUpload}
                      setPublishedDateTime={setPublishedDateTime}
                      invalidType={invalidType}
                      isDragging={isDragging}
                      handleDragEnter={handleDragEnter}
                      handleDragLeave={handleDragLeave}
                      handleDrop={handleDrop}
                      handleFileClick={(elementID: string) =>
                        handleFileClick(elementID)
                      }
                      handleFileChange={handleFileChange}
                      currentBatchIndex={currentBatchIndex}
                      currentBatch={currentBatch}
                      uploadProgress={uploadProgress}
                      addMoreFile={addMoreFile}
                      toast={toast}
                      setToast={setToast}
                    />
                  )}
                </Box>
              </>
            ))}
            {batches.length === 2 &&
              [batches[1]].map((batch) => (
                <>
                  {batch.files.length > 0 && (
                    <Box
                      key={1}
                      sx={{
                        background: "#fff",
                        padding: "8px 12px 24px 12px",
                        borderRadius: "16px",
                        marginBottom: "18px",
                      }}
                    >
                      {batch.isPublished ? (
                        <UploadAccordion
                          index={1}
                          uploadSpeed={uploadSpeedBatch2}
                          uploadProgress={uploadProgress}
                          uploadedStatus={uploadedStatusBatch2}
                          batchData={batch}
                          publishedDateTime={publishedDateTime}
                          uploadingBatchIndex={1}
                          isUploading={isUploading}
                          batchProgress={batchProgress}
                          cancelUpload={cancelUpload}
                          batches={batches}
                          UploadStatus={UploadStatus}
                          setCancelUpload={setCancelUpload}
                          setBatches={setBatches}
                          currentBatchIndex={currentBatchIndex}
                          setCurrentBatchIndex={setCurrentBatchIndex}
                          setUploadingFile={setUploadingFile}
                          setShowFileList={setShowFileList}
                          editedFileNames={editedFileNames}
                          replacedFiles={replacedFiles}
                          fileProgress={fileProgressBatch2}
                          editingAddIndexes={editingAddIndexes}
                          editedAddFileNames={editedAddFileNames}
                          setEditedAddFileNames={setEditedAddFileNames}
                          handleSaveFileName1={handleSaveFileName1}
                          handleEditFileName1={handleEditFileName1}
                          handleDeleteFile1={handleDeleteFile1}
                          handleUndoDelete1={handleUndoDelete1}
                          handleCancelEditFileName1={handleCancelEditFileName1}
                          handleAddToBatch={handleAddToBatch}
                          batchLoaders={batchLoaders}
                          draggedBatchIndex={draggedBatchIndex}
                          handleAddMoreDragEnter={handleAddMoreDragEnter}
                          handleAddMoreDragLeave={handleAddMoreDragLeave}
                          handleDrop1={handleDrop1}
                          handleFileClick={(elementID: string) =>
                            handleFileClick(elementID)
                          }
                          handleFileChange1={(e) =>
                            handleFileChangeAddtoBatch2(e, 1)
                          }
                          invalidBatchIndexes={invalidBatchIndexes}
                          invalidTypeAdd={invalidTypeAdd}
                        />
                      ) : (
                        <FilesForUpload
                          showLoader={showLoader}
                          index={1}
                          uploadSpeed={uploadSpeedBatch2}
                          isCalendarOpen={isCalendarOpen}
                          batchData={batch.files}
                          editingIndex={editingIndex}
                          editedFileNames={editedFileNames}
                          setEditedFileNames={setEditedFileNames}
                          handleSaveFileName={handleSaveFileName}
                          replacedFiles={replacedFiles}
                          handleEditFileName={handleEditFileName}
                          handleDeleteFile={handleDeleteFile}
                          handleUndoDelete={handleUndoDelete}
                          setAddMoreFile={setAddMoreFileBatch2}
                          handleCancel={handleCancel}
                          setIsCalendarOpen={setIsCalendarOpen}
                          setUploadingFile={setUploadingFile}
                          setBatches={setBatches}
                          batchStates={batchStates}
                          setBatchStates={setBatchStates}
                          handleFileUpload={(index: number) =>
                            handleFileUploadBatch(index, batch)
                          }
                          setPublishedDateTime={setPublishedDateTime}
                          invalidType={invalidType}
                          isDragging={isDragging}
                          handleDragEnter={handleDragEnter}
                          handleDragLeave={handleDragLeave}
                          handleDrop={handleDrop}
                          handleFileClick={handleFileClick}
                          handleFileChange={handleFileChange}
                          currentBatchIndex={currentBatchIndex}
                          currentBatch={currentBatch}
                          uploadProgress={uploadProgress}
                          addMoreFile={addMoreFileBatch2}
                          toast={toast}
                          setToast={setToast}
                        />
                      )}
                    </Box>
                  )}
                </>
              ))}
          </>
        )}

        {uploadingFile && !cancelUpload.isOpen && (
          <>
            {!createNewBatch ? (
              <>
                {currentBatch && currentBatch.isPublished && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Link
                      onClick={() => {
                        hanldeCreateNewBatch();
                      }}
                      underline="none"
                      sx={{
                        paddingX: "20px",
                        bgcolor: "#fff",
                        border: "1px solid #FFA737",
                        borderRadius: "8px",
                        height: "44px",
                        marginTop: "14px",
                      }}
                      component={"button"}
                    >
                      <Typography color={"#333"} variant="cbutton">
                        Upload a new batch
                      </Typography>
                    </Link>
                  </Box>
                )}
              </>
            ) : (
              <>
               
                  <>
                    {!showLoader ? (
                      <>
                        {(batches.length === 2 && batches[1].files.length === 0) && (
                          <UploadFileBatch2
                            invalidType={invalidType}
                            isDragging={isDraggingBatch}
                            showLoader={showNewLoader}
                            handleDragEnter={handleDragEnterBatch}
                            handleDragLeave={handleDragLeaveBatch}
                            handleDrop={handleDropNewBatch}
                            handleFileClick={handleFileClickBatch2}
                            handleFileChange={handleFileChangeBatch2}
                            uploadBatchNew={true}
                            setCreateNewBatch={setCreateNewBatch}
                          />
                        )}
                      </>
                    ) : (
                      <CircularProgressScreen
                        showLoader={showNewLoader}
                        uploadProgress={uploadProgress}

                      />
                    )}
                  </>
              </>
            )}
          </>
        )}

        {successUpload && (
          <SuccessUploadScreen
            publishedDateTime={publishedDateTime}
            batches={batches}
            successUploadMore={successUploadMore}
          />
        )}
      </>
    </Popup>
  );
};

export default FileUpload;
